import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../components/common/Layout.jsx';
import HealthyHeartsLayout from '../../components/healthy-hearts/HealthyHeartsLayout.jsx';

import { connect } from 'react-redux';
import { HEALTHY_HEARTS_NAVIGATION } from '../../state/actionTypes.js';

class HeartFacts extends React.Component {
    constructor(props){
        super(props);
        props.healthyHeartsNavigation(2);
    }

    render(){
        return (
            <Layout>
                <HealthyHeartsLayout>
                    <div className="HealthyHeartsMainContainer">
                        <div className="Content">
                            <div className="SectionContainer">
                                <p className="SectionText">On average, a dog's heart pumps 4,000 litres of blood a day.</p>
                                <p className="SectionText">An adult dog's heart beats between 70 and 160 times a minute, compared with a human heart which beats 60 to 100 times a minute.</p>
                                <p className="SectionText">On average, a dog's heart beats 166,000 times per day.</p>
                                <p className="SectionText">It has been established that people who own pets live longer, suffer less stress, and have fewer heart attacks.<sup>1</sup></p>
                                <p className="SectionText">Heart disease is common in dogs and people. In fact, dogs and people share over 400 ailments and illnesses.</p>
                                <div className="ImageContainer">
                                    <Img
                                        fluid={this.props.data.normalHeart.childImageSharp.fluid}
                                        className="TypicalImage"
                                    />
                                </div>
                                <p className="ReferenceText">Reference:</p>
                                <p className="ReferenceText">1. Arhant-Sudhir K., et al. (2011) Pet ownership and cardiovascular risk reduction: supporting evidence, conflicting data and underlying mechanisms. <em>Clin Exp Pharmacol Physiol.</em>;38(11):734-738.</p>
                            </div>
                        </div>
                    </div>
                </HealthyHeartsLayout>
            </Layout>
        )
    }
}

export const query = graphql`{
    normalHeart: file(relativePath: { eq: "healthyHearts/normal-heart.png" }){
        childImageSharp {
            fluid(maxWidth: 950){
                ...GatsbyImageSharpFluid
            }
        }
    }
}`

const mapDispatchToProps = dispatch => {
    return {
        healthyHeartsNavigation: healthyHeartsPage => dispatch({
            type: HEALTHY_HEARTS_NAVIGATION,
            healthyHeartsPage,
        })
    }
}

export default connect(null, mapDispatchToProps)(HeartFacts);
